import { useEffect, useState, type FC, useMemo } from 'react'

import {
  getSubscriptionType,
  isSubscriptionLineItem,
} from '@/scripts/cart/utilities'
import type { AdvancedCartItem } from '@/types/pages/cart'
import { formatPrice } from '@/utilities/number'

import './line-item.scss'

interface LineItemProps {
  bagItem: AdvancedCartItem
}

const LineItem: FC<LineItemProps> = ({ bagItem }) => {
  const [product, setProduct] = useState<undefined | any>(undefined)
  const [variantTitle, setVariantTitle] = useState<string>('')
  const [variantPrice, setVariantPrice] = useState<string>('')
  const [variantCompareAtPrice, setVariantCompareAtPrice] = useState<string>('')
  const [inputQuantity, setInputQuantity] = useState<number>(0)
  const [lineItemImageSrc, setLineItemImageSrc] = useState<string | undefined>(
    undefined,
  )
  const [isSubscriptionItem, setIsSubscriptionItem] = useState<boolean>(false)
  const [subscriptionType, setSubscriptionType] = useState<string>('')
  const [rawData, setRawData] = useState<AdvancedCartItem>(bagItem)
  const [isGift, setIsGift] = useState<boolean>(false)
  const [isYotpoGift, setIsYotpoGift] = useState<boolean>(false)

  const cartItem = (bagItem: AdvancedCartItem) => {
    const {
      merchandise: { id, product, title },
      quantity,
      attributes,
    } = bagItem
    setProduct(product)
    const variantNode = product.variants.edges.find(
      (edge) => edge.node.id === id,
    )?.node!

    setVariantTitle(title)
    setIsGift(
      !!attributes.find(
        (attr) => attr.key === '_gift' && attr.value === 'true',
      ),
    )

    setIsYotpoGift(
      !!attributes.find(
        (attr) => attr.key === '_swell_redemption_id' && attr.value !== 'null',
      ),
    )
    setVariantPrice(
      Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: variantNode.price.currencyCode,
        //@ts-ignore trailingZeroDisplay works on browser but not nodejs
        trailingZeroDisplay: 'stripIfInteger',
      }).format(Number(variantNode.price.amount)),
    )
    if (variantNode.compareAtPrice) {
      setVariantCompareAtPrice(
        formatPrice(Number(variantNode.compareAtPrice.amount)),
      )
    }
    setInputQuantity(quantity)
    setLineItemImageSrc(
      getLineItemImage(
        product?.variants?.edges?.find((edge: any) => edge.node.id === id)?.node
          ?.image?.url || product.featuredImage?.url,
      ),
    )

    const rawData = bagItem
    setRawData(rawData)
    setIsSubscriptionItem(isSubscriptionLineItem(rawData))
    setSubscriptionType(getSubscriptionType(rawData))
  }

  const getLineItemImage = (url?: string) => {
    if (!url) return undefined

    return `${url}&width=800`
  }

  const giftTags = useMemo(() => {
    return (
      rawData?.attributes
        ?.filter((attr) => attr.key.startsWith('_gift_tag_'))
        .map((attr) => ({
          label: attr.key
            .replace('_gift_tag_', '')
            .replace(/_/g, ' ')
            .toUpperCase(),
          theme: attr.value,
        })) || []
    )
  }, [rawData])

  useEffect(() => {
    cartItem(bagItem)
  }, [bagItem])

  const linkToProduct = useMemo(() => {
    const storedPath = rawData?.attributes.find(
      (attribute: { key: string; value: string }) =>
        attribute.key === '_product_path',
    )?.value

    return storedPath || `/products/${product?.handle}`
  }, [rawData, product])

  return (
    <li className="cart-pop-over__line-item">
      <a className="cart-pop-over__line-item__media" href={linkToProduct}>
        <img
          className="cart-pop-over__line-item__media-img"
          width="80"
          height="80"
          loading="lazy"
          src={lineItemImageSrc}
          alt={product?.title}
        />
      </a>
      <div className="cart-pop-over__line-item__content">
        <a
          className="cart-pop-over__line-item__content-body"
          href={linkToProduct}
        >
          <h6 className="cart-pop-over__line-item__title type-h6">
            {product?.title}
          </h6>
          <p className="cart-pop-over__line-item__variant-details type-label">
            {!isGift &&
              (bagItem?.addons?.length ? 'WITH ADD ONS' : variantTitle)}
            {!isGift && ` | `}QTY:&nbsp;
            {inputQuantity}
          </p>
          {variantCompareAtPrice && !isGift && (
            <span className="cart-pop-over__line-item__variant-price cart-pop-over__line-item__strike-price type-label">
              {variantCompareAtPrice}
            </span>
          )}
          {isGift && giftTags && (
            <div className="cart-pop-over__tags">
              {giftTags?.map((tag) => (
                <div
                  className={`cart-pop-over__tag type-label ${
                    tag.theme ? 'theme-' + tag.theme : ''
                  }`}
                >
                  <p>{tag.label}</p>
                </div>
              ))}
            </div>
          )}
          <span className="cart-pop-over__line-item__variant-price type-label">
            {!isGift &&
              ((isYotpoGift && 'FREE') ||
                `${variantPrice}${
                  isSubscriptionItem ? ` ${subscriptionType}` : ''
                }`)}
          </span>
        </a>
      </div>
    </li>
  )
}
export default LineItem
